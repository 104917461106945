import React from 'react';
import _ from 'lodash';
import Slider from "react-slick";
import ScrollTrigger from 'react-scroll-trigger';
import anime from 'animejs';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { safePrefix, markdownify, Link } from '../utils';
import Product from './Product';

const productList = require('../data/productList.json');

export default class ProductBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
    };
    const locale = _.get(this.props, 'pageContext.locale');
    return (
      <div className="product-block-slider">
        <div className="product-block-wrapper-title">
          {_.get(this.props, 'section.title')}
        </div>
        <div className="product-block-wrapper-content">
          {
            productList.products.length > 4 ?
              <Slider {...settings}>
                {
                  productList.map((product, index) =>
                    (
                      <Product key={index} locale={locale} {...product} />
                    )
                  )
                }
              </Slider> :
              productList.products.map((product, index) =>
                (
                  <Product key={index} locale={locale} {...product} />
                )
              )
          }
        </div>
        <div className="product-block-button-wrapper">
          <Link to={`/${locale}${_.get(this.props, 'section.btnUrl')}`}>
            <button className="product-block-button green-btn">{_.get(this.props, 'section.btnLabel')}</button>
          </Link>
        </div>
        <ScrollTrigger onEnter={this.onEnterViewport} className="animation-trigger" />
      </div>
    );
  }
}
