import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { safePrefix } from '../utils';
import Header from './Header';
import Footer from './Footer';
import WebIcon from '../images/icon.png';
import '../sass/main.scss';

export default class Body extends React.Component {
    render() {
        const locale = _.get(this.props, 'pageContext.locale');
        const pageTitle = _.get(this.props, 'pageContext.name') === 'product' ? _.get(this.props, `pageContext.frontmatter.name.${locale}`) : _.get(this.props, 'pageContext.frontmatter.title');
        return (
            <React.Fragment>
                <Helmet>
                    <title>{pageTitle} - {_.get(this.props, `pageContext.site.siteMetadata.title.${locale}`)}</title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="google" content="notranslate" />
                    <script src="https://cdn.polyfill.io/v2/polyfill.min.js"></script>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                    <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+TC&display=swap" rel="stylesheet" />
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')} />
                    <link rel="shortcut icon" href={WebIcon} type="image/png"></link>

                    <meta name="Description" content={_.get(this.props, `pageContext.site.siteMetadata.title.${locale}`)} />
                    <meta property="og:title" content={_.get(this.props, `pageContext.site.siteMetadata.title.${locale}`)} />
                    <meta property="og:description" content={_.get(this.props, `pageContext.site.siteMetadata.title.${locale}`)} />
                    <meta property="og:url" content="https://doona.com" />
                    <meta property="og:locale" content="zh_HK" />
                    {/* <meta property="og:image"
                        content="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/32696418_205611570219295_5138404864690225152_o.jpg?_nc_cat=101&_nc_oc=AQkCSiRaYFsFs2I81seWE9b9oeUQqqTjAMxNSqTThLPeB4nBpF1udtbZxkDRw3OPFZc&_nc_ht=scontent-hkg3-2.xx&oh=5077bba8d5426e4082046030aa8cb971&oe=5DDE996C" /> */}
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Doona" />
                    <meta name="keywords"
                        content="Doona,handbags" />
                </Helmet>
                <div id="page">
                    <Header {...this.props} />
                    <main id="content" className="site-content">
                        {this.props.children}
                    </main>
                    <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
