import React from 'react';
import _ from 'lodash';
import anime from 'animejs';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { safePrefix, markdownify, Link } from '../utils';

export default class StoryBlock extends React.Component {
  constructor(props) {
    super(props);
    this.inventionContainer = React.createRef();
    this.brandContainer = React.createRef();
  }

  componentDidMount() {
    anime.timeline({
        easing: 'easeInOutExpo',
    })
        .add({
            easing: 'easeInOutExpo',
            targets: this.inventionContainer.current,
            translateY: ['50px', '0px'],
            opacity: [0, 1],
        })
        .add({
            easing: 'easeInOutExpo',
            targets: this.brandContainer.current,
            translateY: ['50px', '0px'],
            opacity: [0, 1],
        });
  }

  render() {
    return (
      <div className="story-block-container">
        <div className="story-section" ref={this.inventionContainer}>
          <div className="story-section-img" style={{ backgroundImage: `url(${safePrefix(_.get(this.props, 'section.inventionImg'))})` }}/>
          <div className="story-section-text">
            {_.get(this.props, 'section.inventionTitle')}
            <br/><br/>
            <span dangerouslySetInnerHTML={{ __html: _.get(this.props, 'section.inventionDescription')}} />
          </div>
        </div>
        <div className="story-section" ref={this.brandContainer}>
          <span className="story-section-text" dangerouslySetInnerHTML={{ __html: _.get(this.props, 'section.brandDescription')}} />
          <div className="story-section-img" style={{ backgroundImage: `url(${safePrefix(_.get(this.props, 'section.brandImg'))})` }}/>
        </div>
      </div>
    );
  }
}
