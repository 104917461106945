import React from 'react';
import _ from 'lodash';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { safePrefix, markdownify, Link } from '../utils';

export default class ShopBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="shop-block-container">
          <div className="shop-block-title">{_.get(this.props, 'section.title')}</div>
          <div className="shop-block-description">{_.get(this.props, 'section.description')}</div>
          <div className="order-button-container">
            <Link to={safePrefix(_.get(this.props, 'section.url'))}>
                <div className="order-button green-btn">{_.get(this.props, 'section.btnLabel')}</div>
            </Link>
          </div>
      </div>
    );
  }
}
