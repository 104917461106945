import Footer from './Footer';
import Header from './Header';
import HeroBlock from './HeroBlock';
import StoryBlock from './StoryBlock';
import ReasonBlock from './ReasonBlock';
import ProductBlock from './ProductBlock';
import ProductGalleryBlock from './ProductGalleryBlock';
import ShopBlock from './ShopBlock';
import Layout from './Layout';

export {
    Footer,
    Header,
    HeroBlock,
    StoryBlock,
    ReasonBlock,
    ProductBlock,
    ProductGalleryBlock,
    ShopBlock,
    Layout
};

export default {
    Footer,
    Header,
    HeroBlock,
    StoryBlock,
    ReasonBlock,
    ProductBlock,
    ProductGalleryBlock,
    ShopBlock,
    Layout
};
