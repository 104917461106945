import React from 'react';
import _ from 'lodash';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { safePrefix, markdownify, Link } from '../utils';

export default class HeroBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hero-block-container">
        <div className="product-banner" >
          <div className="product-banner-bg-container" style={{ backgroundImage: `url(${safePrefix(_.get(this.props, 'section.bannerImg'))})` }}/>
          <div className="product-banner-content">
            <div className="product-banner-title">{_.get(this.props, 'section.title')}</div>
            <div className="product-banner-subTitles">
              {
                _.get(this.props, 'section.subTitle').map(subTitle => (
                  <div className="product-banner-subTitle">{subTitle.text}</div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
