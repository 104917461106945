import React from 'react';
import _ from 'lodash';

import { Link, safePrefix } from '../utils';

export default class Header extends React.Component {
  toggleHamburgerHandler(){
    document.getElementById('burger').classList.toggle('open');
    document.getElementById('menu-mobile').classList.toggle('open');
  }
  render() {
    const currentUrl = _.get(this.props, 'pageContext.rawUrl');
    return (
      <header id="masthead" className="site-header outer">
        <div className="inner">
          <div className="site-header-inside">
            {(_.get(this.props, 'pageContext.site.siteMetadata.header.menus')) && <React.Fragment>
              <nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
                <div className="site-nav-inside">
                  {/* Locale Ends */}
                  <ul className="menu menu-left">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.locale.list'), (item, item_idx) => (
                      <li key={item_idx} className={`menu-item ${_.get(this.props, 'pageContext.locale') === item.value ? 'active' : ''}`}>
                        <Link to={safePrefix(`${item.value}/${currentUrl.substr(currentUrl.indexOf('/') + 1)}`)}>{item.key}</Link>
                      </li>
                    ))}
                  </ul>
                  {/* Locale Ends */}

                  {/* Logo Starts */}
                  <div className="site-branding">
                    {_.get(this.props, 'pageContext.site.siteMetadata.header.logo_img') &&
                      <p className="site-logo">
                        <Link to={safePrefix(`${_.get(this.props, 'pageContext.locale')}`)}>
                          <img src={_.get(this.props, 'pageContext.site.siteMetadata.header.logo_img')} alt="Logo" className="site-logo-img" />
                        </Link>
                      </p>
                    }
                  </div>
                  {/* Logo Ends */}

                  {/* Menu Starts */}
                  <ul className="menu menu-right">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.header.menus'), (item, item_idx) => (
                      <li key={item_idx} className={`menu-item ${`/${_.get(this.props, 'pageContext.rawUrl')}/` === item.url ? 'active' : ''}`}>
                        <Link to={safePrefix(`${_.get(this.props, 'pageContext.locale')}${_.get(item, 'url')}`)}>{_.get(item, `title[${_.get(this.props, 'pageContext.locale')}]`)}</Link>
                      </li>
                    ))}
                  </ul>
                  {/* Menu Ends */}
                  {/* Hamburger Starts */}
                  <div id="burger" className="hamburger" onClick={this.toggleHamburgerHandler}>
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                  {/* Hamburger Ends */}
                </div>
              </nav>
              {/* Mobile Menu Starts */}
              <div id="menu-mobile" className="menu-mobile">
                {/* Mobile Menu Pages Starts */}
                <div className="menu-mobile-group" id="menu-mobile">
                  <div className="menu-mobile-group-header"></div>
                  <ul className="menu-mobile-group-content">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.header.menus'), (item, item_idx) => (
                      <li key={item_idx} className={`menu-item ${`/${_.get(this.props, 'pageContext.rawUrl')}/` === item.url ? 'active' : ''}`}>
                        <Link to={safePrefix(`${_.get(this.props, 'pageContext.locale')}${_.get(item, 'url')}`)}>{_.get(item, `title[${_.get(this.props, 'pageContext.locale')}]`)}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Mobile Menu Pages Ends */}
                {/* Mobile Menu Locale Starts */}
                <div className="menu-mobile-group menu-mobile-group">
                  <div className="menu-mobile-group-header"></div>
                  <ul className="menu-mobile-group-content menu-mobile-group-content-locale">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.locale.list'), (item, item_idx) => (
                      <li key={item_idx} className={`menu-item ${_.get(this.props, 'pageContext.locale') === item.value ? 'active' : ''}`}>
                        <Link to={safePrefix(`${item.value}${_.get(this.props, 'pageContext.rawUrl')}`)}>{item.key}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Mobile Menu Locale Ends */}
              </div>
              {/* Mobile Menu Ends */}
            </React.Fragment>}
          </div>
        </div>
      </header>
    );
  }
}
