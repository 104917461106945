import React from 'react';
import _ from 'lodash';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery from 'react-grid-gallery';

const gridHeight = [200,300,400];
export default class ProductGalleryBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="product-gallery-block-container">
        <div className="product-gallery-block-title">
          {_.get(this.props, 'section.title')}
        </div>
        <div ref={this.productBlocks} className="product-gallery-block-content">
          <Gallery enableImageSelection={false} images={
            _.get(this.props, 'section.images').map(item => {
              return {
                src: item.url,
                thumbnail: item.url,
                thumbnailWidth: gridHeight[Math.floor(Math.random() * gridHeight.length)],
                thumbnailHeight: gridHeight[Math.floor(Math.random() * gridHeight.length)],
              }
            })
          }/>
        </div>
      </div>
    );
  }
}
