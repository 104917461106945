import React from 'react';
import _ from 'lodash';

import { safePrefix, markdownify, Link } from '../utils';

export default class Product extends React.Component {
    render() {
        return (
            <Link to={`/${this.props.locale}/${this.props.url}`}>
                <div className="product-block" style={{ backgroundImage: `url(${safePrefix(this.props.img.blockBg)})` }}>
                    <img className="product-block-img" src={safePrefix(this.props.img.cover)}/>
                    <div className="product-block-text-container">
                        <div className="product-block-title">{this.props.name[this.props.locale]}</div>
                        <div className="product-block-description">{this.props.subTitle[this.props.locale]}</div>
                    </div>
                </div>
            </Link>
        );
    }
}
