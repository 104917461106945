import React from 'react';
import _ from 'lodash';
import anime from 'animejs';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { safePrefix, markdownify, Link } from '../utils';

export default class ReasonBlock extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    anime.timeline({
        easing: 'easeInOutExpo',
    })
        .add({
            easing: 'easeInOutExpo',
            targets: this.titleRef.current,
            translateY: ['50px', '0px'],
            opacity: [0, 1],
        })
        .add({
            easing: 'easeInOutExpo',
            targets: this.contentRef.current,
            translateY: ['50px', '0px'],
            opacity: [0, 1],
        });
  }

  render() {
    return (
      <div className="reasons-block-container">
        <div className="reasons-block-title" ref={this.titleRef}>
          {_.get(this.props, 'section.title')}
        </div>
        <div className="reasons-block-content" ref={this.contentRef}>
        {
          _.get(this.props, 'section.reasonBlocks').map((reasonBlock, idx) => (
            <div className="reason-block-container">
              <div className="reason-block-title">{reasonBlock.title}</div>
              <div className="reason-block-subTitle-container">
                <div className="reason-block-scene">Scene {idx+1}</div>
                <div className="reason-block-subTitle">{reasonBlock.subTitle}</div>
              </div>
              {
                reasonBlock.reasons.map((reason, idx) => (
                  <div className="reason-item">
                    <div className="reason-item-img" style={{ backgroundImage: `url(${safePrefix(reason.img)})` }}>
                      <div className="reason-item-number">
                        {idx+1}
                      </div>
                    </div>
                    <div className="reason-item-text">
                      {reason.text}
                    </div>
                  </div>
                ))
              }
            </div>
          ))
        }
        </div>
      </div>
    );
  }
}
