import React from 'react';
import _ from 'lodash';

import { Link, safePrefix, htmlToReact } from '../utils';
import Facebook from '../images/facebook.png';
import Instagram from '../images/instagram.png';
import Whatsapp from '../images/whatsapp.png';

export default class Footer extends React.Component {
  render() {
    const currentUrl = _.get(this.props, 'pageContext.rawUrl');
    return (
      <footer className="footer">
        <div className="footer-content-wrapper">
          <div className="footer-top-container">
            <div className="footer-left-container">
              <img src={_.get(this.props, 'pageContext.site.siteMetadata.footer.logo_img')} />
            </div>
            <div className="footer-middle-container">
              <div className="footer-social-media">
                <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.facebook')}>
                  <img src={Facebook} />
                </Link>
              </div>
              <div className="footer-social-media">
                <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.whatsapp')}>
                  <img src={Whatsapp} />
                </Link>
              </div>
              <div className="footer-social-media">
                <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.instagram')}>
                  <img src={Instagram} />
                </Link>
              </div>
            </div>
            <div className="footer-right-container">
              <div className="footer-site-map">
                <div className="footer-site-map-group-title">
                  選擇語言
                </div>
                {
                  _.get(this.props, 'pageContext.site.siteMetadata.locale.list').map((locale) =>
                    <div className="footer-site-map-group-item">
                      <Link to={safePrefix(`${locale.value}/${currentUrl.substr(currentUrl.indexOf('/') + 1)}`)}>
                        {locale.key}
                      </Link>
                    </div>
                  )
                }
              </div>
              {
                _.get(this.props, 'pageContext.site.siteMetadata.footer.menus.groups').map((group) =>
                  <div className="footer-site-map">
                    <div className="footer-site-map-group-title">
                      {_.get(group, `title.${_.get(this.props, 'pageContext.locale')}`)}
                    </div>
                    {_.get(group, 'items').map((item) =>
                      <div className="footer-site-map-group-item">
                        <Link to={safePrefix(`${_.get(this.props, 'pageContext.locale')}${_.get(item, 'url')}`)}>
                          {item.title[_.get(this.props, 'pageContext.locale')]}
                        </Link>
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          </div>
          <div className="footer-bottom-container">COPYRIGHT © DOONA {new Date().getFullYear()} ALL RIGHT RESERVED</div>
        </div>
      </footer>
    );
  }
}
